<h2 mat-dialog-title>Peças não encontradas</h2>
<mat-dialog-content>
  <p>
    Verifique a escrita do código ou o nome no cabeçalho das tabelas. Os nomes devem estar da seguinte forma: 'Valor', 'Código' e 'Quantidade'
  </p>
  <table mat-table [dataSource]="notFoundParts">
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef> Código </th>
      <td mat-cell *matCellDef="let part"> {{ part.code }} </td>
    </ng-container>
    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef> Quantidade </th>
      <td mat-cell *matCellDef="let part"> {{ part.quantity }} </td>
    </ng-container>
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef> Valor </th>
      <td mat-cell *matCellDef="let part"> {{ part.value | currency: "BRL" }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button type="button" color="primary" mat-dialog-close cdkFocusInitial>Ok</button>
</mat-dialog-actions>