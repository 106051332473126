import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Supplier } from '../../../interface/supplier';

@Component({
  selector: 'app-choose-card-adm',
  templateUrl: './choose-card-adm.component.html',
  styleUrls: ['./choose-card-adm.component.scss']
})
export class ChooseCardAdmComponent {

  public selectedAdm: Supplier = { id: '' };
  public cardAdms: Supplier[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { cardAdms?: Supplier[] },
    private dialogRef: MatDialogRef<ChooseCardAdmComponent>
  ) {
    if (this.data && this.data.cardAdms) {
      this.cardAdms = data.cardAdms
    }
  }

  public close(save: boolean){
    this.dialogRef.close(save ? (this.selectedAdm.id || '') : null);
  }

}


