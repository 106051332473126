import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import ImportedPart from 'src/app/class/ImportedPart';

@Component({
  selector: 'app-parts-not-found-by-xls-import',
  templateUrl: './parts-not-found-by-xls-import.component.html',
  styleUrl: './parts-not-found-by-xls-import.component.scss'
})
export class PartsNotFoundByXlsImportComponent {

  notFoundParts: ImportedPart[];
  
  columns = ['code', 'quantity', 'value'];
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { notFoundParts: ImportedPart[] },
  ) {
    if (data?.notFoundParts) {
      this.notFoundParts = data.notFoundParts
    }
  }
}
