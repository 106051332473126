<h1 mat-card-title>Administradora de cartões</h1>
<span mat-card-subtitle>Selecione a administradora de cartões para os pagamentos em cartão de crédito</span>

<div class="mt-3">
    <mat-form-field>
        <mat-label>Administrador</mat-label>
        <mat-select [(ngModel)]="selectedAdm">
            <mat-option *ngFor="let adm of cardAdms"
                        [value]="adm">
                {{ adm.name }}
            </mat-option>
            <mat-option [value]="{ id: '' }"> Nenhum </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div mat-dialog-actions
     class="d-flex justify-content-end">
    <button mat-raised-button
            (click)="close(true)"
            color="primary">OK</button>
</div>